@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(http://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);
@import url('https://fonts.googleapis.com/css2?family=Sarabun&display=swap');

* {
  font-family: 'Roboto', 'Sarabun', sans-serif;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  scroll-behavior: smooth;
  /* background: rgb(32,52,116);
  background: linear-gradient(180deg, rgb(31,52,113) 0%, rgb(31,53,115) 40%, rgb(33,56,122) 50%, rgb(36,61,134) 60%, rgb(40,68,152) 65%, rgb(45,77,174) 70%, rgb(52,88,202) 80%, rgb(60,103,238) 100%); */
}